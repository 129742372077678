import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Head from 'components/head';
import HeaderImage from 'components/header/header-image/headerImage.js';
import FrontFace from '../images/images-js/frontFace';

const About = ({ data }) => (
  <Layout buttonClass="white">
    <HeaderImage title="O MNIE"/>
    <div className="container about-page">
      <div className="row">
      <div className="aboutPage__headline mt-7">
      <h3>ADWOKAT BARTOSZ BATOR</h3>
    </div>
      </div>
      <div className="row">
        <div className="col-md-6 pl-0 mt-5">
        <p>Warszawski adwokat, publicysta i szkoleniowiec.</p>
          <p>
Ukończył Wydział Prawa i Administracji Uniwersytetu Warszawskiego oraz Podyplomowe Studium
Prawa Własności Intelektualnej WPiA UW. Był przedstawicielem Helsińskiej Fundacji Praw Człowieka,
prawnikiem w renomowanych kancelariach w Warszawie oraz w Biurze Polskiej Izby Rzeczników
Patentowych. Pracował dla instytucji publicznych m.in. Narodowego Instytutu Fryderyka Chopina,
Teatru Polskiego w Warszawie czy Urzędu m.st. Warszawy. </p>
<p>
Obecnie w <strong>obszarze jego zainteresowań jest doradztwo prawne w zakresie porządkowania spraw
majątkowych osób fizycznych oraz małych i średnich przedsiębiorców z terenu Warszawy ale także Mazowsza oraz pozostałych rejonów Polski.</strong>  W szczególności zajmuje się
sprawami z zakresu prawa nieruchomości, spadkowego, rodzinnego i powództwami frankowymi.
</p>
<p>
Jak dziennikarz pracował dla Dziennika Polska Europa Świat, portalu dziennik.pl, Dziennika
Finansowego The Wall Street Journal, TVP 1 i TVN Warszawa. W latach 2013-2016 autor i prowadzący
poradnikową audycję prawną w Programie 1 Polskiego Radia. W latach 2016-2020 wykładowca
Krajowej Szkoły Administracji Publicznej. <strong>Stały komentator Dziennika Gazety Prawnej.
Członek Izby Adwokackiej w Warszawie.</strong> </p>
        </div>
        <div className="col-md-6 mt-5">
          <FrontFace/>
        </div>
      </div>
    </div>
  </Layout>
);

About.propTypes = {
  data: PropTypes.object.isRequired,
};

export default About;

export const query = graphql`
  query AboutQuery {
    aboutJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
